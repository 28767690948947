import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const handleFocus = (evt) => {
  evt.target.parentNode.parentNode.classList.add("focus");
};

const handleBlur = (evt) => {
  evt.target.parentNode.parentNode.classList.remove("focus");
};

const GetInTouchPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Get in touch with Masterton based website builders | Sixvees
        </title>
        <meta
          name="description"
          content="Have a question about building a website? Organise to meet with Sixvees for free coffee and website advice at Mitre10 Mega Cafe Masterton."
        ></meta>
      </Helmet>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <h1 className="mb-5">Get in Touch</h1>
                <p>
                  Meet with us for free coffee and advice at Mitre10 Mega Cafe
                  Masterton.
                </p>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    159-167 Ngaumutawa Rd, <br />
                    Masterton 5840
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <a href="mailto:hello@sixvees.co.nz">hello@sixvees.co.nz</a>
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-phone fa-rotate-90"></i>
                    </span>
                    <a href="tel:0221523912">022 152 3912</a>
                  </li>
                </ul>
              </div>
              <div className="pt-4 pt-lg-0 col-lg-7 ml-auto">
                <div
                  role="form"
                  className="wpcf7"
                  id="wpcf7-f550-p17-o1"
                  lang="en-NZ"
                  dir="ltr"
                >
                  <form
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                    action="/thank-you"
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="form-group">
                      <label className="sr-only" htmlFor="fName">
                        First name
                      </label>
                      <span className="wpcf7-form-control-wrap first-name">
                        <input
                          type="text"
                          name="first-name"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                          id="fName"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="First name"
                          required
                        />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="sr-only" htmlFor="lName">
                        Last name
                      </label>
                      <span className="wpcf7-form-control-wrap last-name">
                        <input
                          type="text"
                          name="last-name"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                          id="lName"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Last name"
                          required
                        />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="sr-only" htmlFor="phone">
                        Phone number
                      </label>
                      <span className="wpcf7-form-control-wrap phone-number">
                        <input
                          type="text"
                          name="phone-number"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                          id="phone"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Phone number"
                          required
                        />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="sr-only" htmlFor="email">
                        Email address
                      </label>
                      <span className="wpcf7-form-control-wrap email-514">
                        <input
                          type="email"
                          name="email-address"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                          id="email"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Email Adress"
                          required
                        />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="sr-only" htmlFor="message">
                        Message
                      </label>
                      <span className="wpcf7-form-control-wrap message">
                        <textarea
                          name="message"
                          rows={4}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                          id="message"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Message"
                          required
                        />
                      </span>
                    </div>
                    <div className="text-right">
                      <button
                        type="submit"
                        className="wpcf7-form-control wpcf7-submit btn btn-outline-info mt-5"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="mapContainer" className="embed-responsive">
          <iframe
            title="Sixvees Google map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12054.17742421783!2d175.6360187!3d-40.9476523!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa961e458586536bd!2sSixvees!5e0!3m2!1sen!2snz!4v1584586112886!5m2!1sen!2snz"
            width={"100%"}
            height={450}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
          />
        </div>
      </main>
    </Layout>
  );
};

export default GetInTouchPage;
